.App {
  text-align: left;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header h1 {
  font-size: 3.5rem;
  margin-bottom: 0;
}

.App-header p {
  font-size: 1.8rem;
  margin-top: 0;
  color: #61dafb;
}

.App-header nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.App-header nav ul li {
  margin: 0 1rem;
}

.App-header nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.App-header nav ul li a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.App-section {
  padding: 4rem 2rem;
  max-width: 1000px;
  margin: 0 auto;
}

.section-title {
  font-size: 2.5rem;
  color: #343a40;
  margin-bottom: 1.5rem;
  border-bottom: 3px solid #007bff;
  padding-bottom: 0.5rem;
}

.about-text {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.education-container {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.education-card {
  border-left: 4px solid #007bff;
  padding-left: 1rem;
}

.education-card h4 {
  margin: 0 0 0.5rem 0;
  color: #007bff;
}

.education-card p {
  margin: 0.25rem 0;
}

.education-percentage {
  font-weight: bold;
  color: #28a745;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.skill-tag {
  background-color: #e9ecef;
  color: #495057;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.skill-tag:hover {
  background-color: #007bff;
  color: white;
  transform: translateY(-2px);
}

.experience-timeline {
  position: relative;
  padding-left: 2rem;
}

.experience-timeline::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #007bff;
}

.experience-card {
  position: relative;
  margin-bottom: 2rem;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.experience-card::before {
  content: '';
  position: absolute;
  left: -2.5rem;
  top: 1.5rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #007bff;
  border: 3px solid white;
}

.experience-header h3 {
  margin: 0 0 0.5rem 0;
  color: #007bff;
}

.company {
  font-weight: bold;
  margin: 0;
}

.period {
  color: #6c757d;
  font-style: italic;
  margin: 0.25rem 0 1rem 0;
}

.responsibilities {
  padding-left: 1.5rem;
}

.responsibilities li {
  margin-bottom: 0.5rem;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
}

.project-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.project-card h3 {
  color: #007bff;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.project-card p {
  color: #6c757d;
  margin-bottom: 1rem;
}

.project-link {
  display: inline-flex;
  align-items: center;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.project-link:hover {
  color: #0056b3;
}

.project-link svg {
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
}

.project-link:hover svg {
  transform: translateX(3px);
}

.contact-container {
  display: flex;
  gap: 2rem;
}

.contact-info {
  flex: 1;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.contact-item svg {
  margin-right: 1rem;
  color: #007bff;
}

@media (max-width: 768px) {
  .App-header h1 {
    font-size: 2.5rem;
  }

  .App-header p {
    font-size: 1.4rem;
  }

  .App-section {
    padding: 2rem 1rem;
  }

  .section-title {
    font-size: 2rem;
  }

  .projects-grid {
    grid-template-columns: 1fr;
  }

  .contact-container {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .App-header h1 {
    font-size: 2rem;
  }

  .App-header p {
    font-size: 1.2rem;
  }

  .App-section {
    padding: 1.5rem 0.5rem;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .project-card h3 {
    font-size: 1.2rem;
  }

  .project-card p {
    font-size: 0.9rem;
  }

  .project-link {
    font-size: 0.9rem;
  }

  .contact-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .contact-item svg {
    margin-bottom: 0.5rem;
  }

  .contact-item span {
    font-size: 0.9rem;
  }
}

